<template>
    <div> <!-- do not remove empty root element. solves hover shadow delay in grid -->
        <div
            class="
                panel
                hover:shadow-2xl 
                h-full
                transition 
                duration-200 
                ease-in-out
                cursor-pointer
                overflow-hidden
                flex
                flex-col
                
                text-sm
                md:text-base
            "
            @click="$emit('view', offer);">
            
            <div 
                class="
                    flex flex-col bg-gray-100
                    p-4
                    pb-6
                    -m-4
                    mb-2
                    
                    md:p-8 
                    md:-m-8 
                    md:mb-6 
                ">
                
                <div class="flex items-center mb-3">
                    <i 
                        v-if="offer.owner && offer.owner.username && user && user.username == offer.owner.username"
                        class="fas fa-star text-orange-500 mr-4 text-lg"
                        title="You are the creater of this offer"
                    />
                    
                    <div class="flex-grow leading-tight text-gray-500">
                        <div v-if="offer.acceptedAt">{{$t('offers.accepted')}} {{ offer.acceptedAt | moment('from') }}</div>
                        <div v-else-if="offer.declinedAt">{{$t('offers.declined')}} {{ offer.declinedAt | moment('from') }}</div>
                        <div v-else>{{offer.updatedAt == offer.createdAt ? $t('offers.created') : $t('offers.updated') }} {{ offer.updatedAt | moment('from') }}</div>
                    </div>
                    <div class="flex-none flex items-center ml-1">
                        <div 
                            v-if="offerUnreadMessages && offerUnreadMessages.length"
                            class="p-2 rounded-full mr-4 text-white bg-orange-500 flex items-center justify-center ">
                            <i 
                                class="fas fa-envelope text-sm"
                                :title="$tc('offers.newMessagesText', offerUnreadMessages.length)"
                            />
                        </div>
                        
                        <i 
                            v-if="followup"
                            class="fa-bell text-lg text-gray-400 mr-4"
                            :class="offer.followedupAt ? 'far' : 'fas'"
                            :title="followup.text + '. ' + followup.datetime"
                        />
                        <div 
                            class="px-2 py-1 rounded capitalize"
                            :class="status.class">
                            
                            <div v-if="offer.status && offer.status == 'active' && offer.expireAt && offer.expireAt < Date.now()">
                                {{ $t('offers.statuses.expired') }}
                            </div>
                            <div v-else>
                                {{status.title}}
                            </div>
                        </div>
                    </div>
                </div>
                
                <div 
                    v-if="offer.status == 'active'"
                    class="relative">
                    
                    <div class="bg-white border border-gray-200 rounded-xl h-2 w-full flex overflow-hidden relative">
                        <div 
                            class="bg-cyan rounded-xl"
                            :style="`
                                width: ${hibernationInfo.percent}%;
                            `"
                        />
                        <div 
                            v-if="offer.followupAt "
                            class="absolute top-0 bottom-0 border-r border-gray-400"
                            :style="`
                                width: ${hibernationInfo.followupPercent}%;
                            `"
                        />
                        <div 
                            v-else-if="offer.followedupAt "
                            class="absolute top-0 bottom-0 border-r border-white"
                            :style="`
                                width: ${hibernationInfo.followedupPercent}%;
                            `"
                        />
                    </div>
                    
                    <div class="w-full mt-2 opacity-50 uppercase text-xs">
                        <div class="absolute left-0">
                            {{$t('offers.sent')}}
                        </div>
                        <div 
                            v-if="offer.followupAt"
                            class="absolute"
                            :style="`
                                left: ${hibernationInfo.followupPercent}%;
                                margin-left: -2.1rem;
                            `">
                            {{$t('offers.followUp')}}
                        </div>
                        <div 
                            v-else-if="offer.followedupAt"
                            class="absolute"
                            :style="`
                                left: ${hibernationInfo.followedupPercent}%;
                                margin-left: -2.1rem;
                            `">
                            
                            {{$t('offers.followedUp')}}
                        </div>
                        
                        <div class="absolute right-0">
                            {{ $tc('general.days', inDays) }}
                        </div>
                    </div>
                </div>
                
            </div>
            
            <div class="">
                <div class="">
                    <!-- <h4 class="font-normal">Customer</h4> -->
                    
                    <div v-if="offer.customerId">
                        <h2 class="text-black leading-none">
                            {{offer.contact && offer.contact.name ? offer.contact.name : ''}}
                        </h2>
                        <!-- <pre>{{customer}}</pre> -->
                        <span v-if="customer.isCompany">{{customer.name}}</span>
                        <!-- <span v-else>{{contact.name }}</span> -->
                        
                        <!-- <div 
                            v-for="customer in [customerIdToCustomer( offer.customerId )]"
                            :key="customer.customerId">
                            
                            <div 
                                v-if="customer.name" 
                                class="truncate pr-4">
                                {{customer.name}}
                            </div>
                            
                            <div 
                                v-if="customer.phone" 
                                class="truncate pr-4">
                                {{customer.phone}}
                            </div>
                            
                            <div 
                                v-if="customer.email" 
                                class="truncate pr-4">
                                {{customer.email}}
                            </div>
                        </div> -->
                    </div>
                    <div v-else>
                        {{$t('offers.noCustomerYet')}}
                    </div>
                    
                </div>
                
                <!-- <div 
                    v-if="followup"
                    class="mt-4">
                    
                    <h4>Follow Up</h4>
                    {{followup.text}} <br>
                    {{followup.datetime}} ({{followup.humanFrom}})
                </div> -->
                
                <!-- <div class="mt-4">
                    <div v-if="offerProducts && offerProducts.length">
                        <div 
                            v-for="(product, index) in offerProducts.filter( (p,i) => i < 3 )"
                            :key="index">
                            
                            <div v-if="product">
                                <div 
                                    class="truncate"
                                    v-if="product.name">
                                    <span class="w-3 h-3 bg-gray-300 inline-block mr-1 rounded"/>
                                    {{product.qty}} x 
                                    {{product.name}}
                                </div>
                            </div>
                        </div>
                        
                        <div v-if="offerProducts.length > 3">
                            <span class="w-3 h-3 bg-gray-300 inline-block mr-1 rounded" />
                            {{$tc('offers.productsCountText', (offerProducts.length - 3) )}}
                        </div>
                    </div>
                    <div v-else>
                        {{$t('offers.noProductsYet')}}
                    </div>
                </div>
                
                <div class="mt-4">
                    {{$t('offers.total')}} {{priceTotal}}
                </div> -->
                
                <div class="mt-4">
                    {{$t('offers.total')}} {{currencyFormat(offer.priceTotal)}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        offer: {
            type: Object,
            required: true,
        }
    },
    
    computed: {
        inDays(){
            let days = 0;
            
            if ( this.offer.expireAt ) {
                days = this.$moment.duration( Math.round((this.offer.expireAt - this.offer.updatedAt) / 1000) ,'seconds').days();
            }
            else {
                days = this.account.hibernate.days;
            }
            
            return days;
        },
        
        user(){
            return this.$store.getters.getUser;
        },
        
        offerUnreadMessages(){
            return this.$store.getters.getMessages.filter( m => {
                if (
                    !m.readAt && 
                    m.from != 'support' && 
                    m.messageId.split(':')[0] == this.offer.offerId) {
                    
                    return true
                }
            });
        },
        
        allProducts(){
            return this.$store.getters.getProducts;
        },
        
        offerProducts(){
            if (this.offer.products && this.offer.products.length) {
                return this.offer.products.map( product => {
                    if (this.allProducts && this.allProducts.length) {
                        const productOriginal = this.allProducts.find( p => p.productId == product.productId);
                
                        return {
                            ...productOriginal,
                            ...product,
                            // qty: product.qty,
                            // discount: product.discount,
                        };
                    }
                });
            }
            return null
        },
        
        // priceTotal(){
        //     // if (this.offer.show && this.offer.show.vatCalculation !== 'undefined' && this.offer.show.vatCalculation) {
        //     //     return this.offer.priceTotalIncludedVatFormated;
        //     // }
        // 
        //     return this.offer.priceTotalFormated
        // },
        
        status(){
            let response = {
                title: this.$t('offers.statuses.'+this.offer.status)
            }
            
            switch (this.offer.status) {
                case 'draft':
                    response.class = 'bg-yellow-500 text-white';
                    break;
                case 'active':
                    if ( this.offer.expireAt && this.offer.expireAt < Date.now() ) {
                        response.class = 'bg-blue-800 text-white';
                    }
                    else {
                        response.class = 'bg-cyan text-white';
                    }
                    break;
                case 'accepted':
                    response.class = 'bg-green-500 text-white';
                    break;
                case 'declined':
                    response.class = 'bg-red-500 text-white';
                    break;
                case 'deleted':
                    response.class = 'bg-gray-500 text-white';
                    break;
            }
            
            return response;
        },
        
        followup(){
            let response;
            
            if ( this.offer.followedupAt ) {
                response = {
                    // text: `Was followed up with ${this.offer.followupMethod.toUpperCase()}`,
                    text: 'Was followed up',
                    datetime: this.$moment(this.offer.followedupAt).format('LLLL'),
                    humanFrom: this.$moment(this.offer.followedupAt).from(),
                };
            }
            else if ( 
                this.offer.followupAt && 
                this.offer.status == 'active' ) {
                
                response = {
                    // text: `Will follow up by ${this.offer.followupMethod.toUpperCase()}`,
                    text: `Will follow up`,
                    datetime: this.$moment(this.offer.followupAt).format('LLLL'),
                    humanFrom: this.$moment(this.offer.followupAt).from(),
                };
            }
            
            return response;
        },
        
        hibernationInfo(){
            let info = {
                now: new Date().getTime(),
                endAt: null,
            };
            
            if (this.offer.expireAt) {
                info.endAt = this.offer.expireAt;
            }
            else {
                info.endAt = this.offer.updatedAt + ( (this.account.hibernate.days * 86400)*1000 );
            }
            
            info.percent =  (info.now - this.offer.updatedAt ) / (info.endAt - this.offer.updatedAt ) * 100;
            info.followupPercent =  this.offer.followupAt ? (this.offer.followupAt - this.offer.updatedAt ) / (info.endAt - this.offer.updatedAt ) * 100 : null;
            info.followedupPercent = this.offer.followedupAt ? (this.offer.followedupAt - this.offer.updatedAt ) / (info.endAt - this.offer.updatedAt ) * 100 : null;
            
            return info;
        },
        
        customer(){
            if (!this.offer.customerId) {
                return
            }
            
            const allCustomers = this.$store.getters.getCustomers;
            if (!allCustomers) {
                return
            }
            
            return allCustomers.find( c => c.customerId == this.offer.customerId);
        },
    },
    
    data() {
        return {
            loading: null,
        }
    }
}
</script>

<style lang="scss" >

</style>
